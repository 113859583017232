import React from "react";
import "./CardItem.css";
import { FaCartPlus } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { setCartitemsNumber } from "../../State/State";

const CardItem = ({
  itemName,
  type,
  ingredients,
  id,
  price,
  thaliSpecial,
  data,
}) => {
  const dispatch = useDispatch()
  const cartItemsSNumber = useSelector(state => state.cartItemsNumber)
  const handleAddtoCart = () => {
    let cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const isAlreadyInCart = cartItems.findIndex((el) => el.item === itemName);
    console.log(isAlreadyInCart);
    if (isAlreadyInCart !== -1) {
      console.log("found");
      return;
    }
    let addedItems = [...cartItems, { item: itemName, price }];
    let stringAddedItems = JSON.stringify(addedItems);
    dispatch(setCartitemsNumber({cartItemsNumber: JSON.parse(stringAddedItems)}))
    window.localStorage.setItem("cart", stringAddedItems);
    window.dispatchEvent(new Event("cartUpdated"));
  };
  return (
    <section className="app__lunch-carditem">
      <div className="carditem_details_seperator">
        <h3>
          {id && id + "."} {itemName} <span>{type}</span>
        </h3>
        <p>{ingredients}</p>
        <h3 className="app__lunch_price">{price && "Є " + price}</h3>
        {thaliSpecial &&
          thaliSpecial.map((thali, i) => {
            return (
              <>
                <h3 key={i}>
                  {thali.id && thali.id + "."} {thali.name} <span>{type}</span>
                </h3>
                <p key={i}>{thali.description}</p>
              </>
            );
          })}
      </div>
      {data?.nimi ? (
        ""
      ) : (
        <div className="carditem_addtocart" onClick={handleAddtoCart}>
          <FaCartPlus color="white" size={24} />
        </div>
      )}
    </section>
  );
};

export default CardItem;
