import React, { useState, useEffect } from "react";
import { images } from "../../constants";
import CardItem from "../ThePortions/CardItem";
import "./SpecialMenu.css";
import mainmenu from "../../constants/menu";
import { useNavigate } from "react-router";
import "../ThePortions/CardItem.css";
import { useSelector } from "react-redux";

const SpecialMenu = () => {
  const [sectionSelected, setSectionSelected] = useState(null);
  const [fixed, setFixed] = useState(false);
  const alacarte = useSelector((state) => state.alacarte);
  const navigate = useNavigate();
  const inEnglish = useSelector((state) => state.inEnglish);

  const fetchData = async () => {
    try {
      const response = await fetch("https://akhanda-backend.onrender.com/api/alacarte", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    setSectionSelected(alacarte[0])

    const handleScroll = () => {
      setFixed(window.scrollY >= 400);
    };

    const href = window.location.href.split("#").pop();
    const element = document.getElementById(href);
    if (element && alacarte) {
      element.scrollIntoView({ behavior: "smooth" });
      const selected =  alacarte.menu.find((item) => item.section === href);
      if (selected) {
        setSectionSelected(selected);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = (dayFound) => {
    setSectionSelected(dayFound);
  };
  if (!sectionSelected  || !alacarte){
    return (
      <img src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.behance.net%2Fgallery%2F31234507%2FOpen-source-Loading-GIF-Icons-Vol-1&psig=AOvVaw2gsQ3IsMQx0FJz_dD4n5__&ust=1721603715143000&source=images&cd=vfe&opi=89978449&ved=0CBAQjRxqFwoTCPDKtODftocDFQAAAAAdAAAAABAY" />
    )
  }

  return (
    <div>
      <div className="app__lunchmenu-frontphoto">
        <img src={images.ravintola} alt="Ravintola" />
        <div className="app__specialmenu_akhanda" data-aos="fade-right">
          <div className="APP_LUNCHMENU-H1-speciamenu">
            Akhanda Nepalilainen ravintola
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.google.com/maps/dir/60.1984943,24.9421778/akhanda+nepalilainen+ravintola/@60.2157766,24.8691566,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x468df7c078ac2f8b:0xda627ee2d0594d3a!2m2!1d24.8758813!2d60.2157276?entry=ttu"
            className="lunch-time-buffet"
          >
            Valimotie 2, 00380 Helsinki
          </a>
        </div>
        <div className="app__lunchmenu-front-buttons">
          <button onClick={() => navigate("/alacarte")} className="custom__button">
            A-La-Carte
          </button>
          <button onClick={() => navigate("/lounas")} className="custom__button">
            {inEnglish ? "Lunch" : "Lounas"}
          </button>
        </div>
      </div>
      <div
        className={`app__lunchmenu-left-smallscreen ${fixed ? "fixed-class" : ""}`}
      >
        { alacarte && alacarte?.map((data, index) => (
          <a
            style={{ maxInlineSize: "700px" }}
            href={`#${data.section === "OLUET,SIIDERIT,LONKEROT" ? "olut" : data.section}`}
            className={`app__lunchmenu-button ${data.section === sectionSelected.section ? "clicked-button-smallscreen" : ""}`}
            key={index}
            onClick={() => handleClick(data)}
          >
            {data.section}
          </a>
        ))}
      </div>

      <div className="app__lunchmenu">
        <div
          className="app__lunchmenu-left sidebar-sticky"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <h1>A-La-Carte</h1>
          <div className="app__lunchmenu-buttons">
            <div className="app__lunch-image1">
              <img src={images.tomato} alt="Tomato" />
            </div>
            {alacarte.map((data, index) => (
              <a
                href={`#${data.section === "OLUET,SIIDERIT,LONKEROT" ? "olut" : data.section}`}
                className={`app__lunchmenu-button ${data.section === sectionSelected.section ? "clicked-button" : ""}`}
                key={index}
                onClick={() => handleClick(data)}
              >
                {data.section}
              </a>
            ))}
          </div>
        </div>

        <div data-aos="fade-left" className="app__lunchmenu-right">
          {alacarte.map((menu, index) => (
            <section
              className="app__specialmenu-items link"
              id={menu.section === "OLUET,SIIDERIT,LONKEROT" ? "olut" : menu.section}
              key={index}
            >
              <div className="div-break"></div>
              <h1>{menu.section}</h1>
              <div className="app__lunchMenu-items">
                {menu.items.map((data, i) => (
                  <CardItem
                    key={i}
                    type={data.type}
                    ingredients={inEnglish?data.ingredients:data.description}
                    itemName={data.name}
                    price={data.price}
                    thaliSpecial={data.items}
                  />
                ))}
              </div>
            </section>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SpecialMenu;
