import React from 'react'
import "./Notice.css"
import { useDispatch, useSelector } from 'react-redux'
import { MdRestaurant } from 'react-icons/md'
import { FaStarOfLife } from 'react-icons/fa'
const Notice = () => {
    const startingDate = useSelector(state => state.startingTime)
    const dispatch = useDispatch()
    return (
        <div style={{height:"30px"}} className='app__cart-price-rolling'>

            <h1>
                <FaStarOfLife color="#bcffbd" style={{ marginRight: "5px", marginLeft: "5px" }} /> LOUNASBUFFET: 10:30-15:00 <FaStarOfLife color="#bcffbd" style={{ marginRight: "5px", marginLeft: "5px" }} />
            </h1>
            <h1>
                <FaStarOfLife color="#bcffbd" style={{ marginRight: "5px", marginLeft: "5px" }} />AUKITÄNÄÄN: {startingDate} <FaStarOfLife color="#bcffbd" style={{ marginRight: "5px", marginLeft: "5px" }} />
            </h1>
            
            
            



        </div>
    )
}

export default Notice
