import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cartItemsOrder: [],
    booking: false,
    weekDay : null,
    cartItemsNumber : 0,
    startingTime: "",
    inEnglish: false,
    lunch:null,
    alacarte:null
    
    
}

export const AkhandaSlice = createSlice({
    name: "akhanda",
    initialState,
    

    reducers: {
        setCartItems:(state,actions) => {
            state.cartItemsOrder = actions.payload.cart
        },
        setBooking :(state,actions) => {
            state.booking = actions.payload.booking
        },
        setWeek :(state,actions) => {
            state.weekDay = actions.payload.weekDay
        },
        setCartitemsNumber:(state,actions) => {
            state.cartItemsNumber = actions.payload.cartItemsNumber
        },
        setStartingTime:(state,actions) => {
            state.startingTime =actions.payload.startingTime
        },
        setInEnglish:(state,actions) => {
            state.inEnglish = actions.payload.language
        },
        setLunch:(state,actions) => {
            state.lunch = actions.payload.lunch
        },
        setAlacarte:(state,actions) => {
            state.alacarte =actions.payload.alacarte
        }
       

    }
})
export const {setCartItems,setBooking,setWeek,setCartitemsNumber,setStartingTime,setInEnglish,setLunch,setAlacarte} = AkhandaSlice.actions ;
export default AkhandaSlice.reducer ;