

const LunchItems = [
  {
    "päivä": "Maanantai",
    "tuotteet": [
      { "id": 1, "nimi": "BASMATIRIISI", "tyyppi": "(L.G,Vegaani)", "ainesosat": "", "eng_description": "" },
      { "id": 2, "nimi": "KEITETYT KASVIKSET", "tyyppi": "(L.G,Vegaani)", "ainesosat": "", "eng_description": "" },
      { "id": 3, "nimi": "NUUDELIT", "tyyppi": "(L,Vegaani)", "ainesosat": "Paistettuja nuudeleita ja vihanneksia soijakastikkeella", "eng_description": "Fried noodles and vegetables with soy sauce" },
      { "id": 4, "nimi": "VEG KOFTA", "tyyppi": "(L,G)", "ainesosat": "Vihannespyöryköitä tomaatti-, sipuli- ja kermaisessa kastikkeessa", "eng_description": "Vegetable balls in a tomato, onion, and creamy sauce" },
      { "id": 5, "nimi": "BHINDI MASALA", "tyyppi": "(L.G,Vegaani)", "ainesosat": "Paistettua okraa paprikan ja perunoiden kanssa sipulin, tomaatin, inkiväärin ja maustekastikkeen kanssa", "eng_description": "Fried okra with bell peppers and potatoes in an onion, tomato, ginger, and spice sauce" },
      { "id": 6, "nimi": "BUTTER CHICKEN", "tyyppi": "(L,G)", "ainesosat": "Yrtti- ja jogurttimarinoituja tandoori-uunissa grillattuja kananfileepaloja tomaatti-, voin ja kermaisen kastikkeen kanssa", "eng_description": "Herb and yogurt marinated tandoori grilled chicken fillet pieces in a tomato, butter, and creamy sauce" },
      { "id": 7, "nimi": "POSSU TIKKA MASALA", "tyyppi": "(L,G)", "ainesosat": "Yrtti- ja jogurttimarinoituja tandoori-uunissa grillattuja possunfileepaloja inkivääri-, tomaatti- ja maustekastikkeen kanssa", "eng_description": "Herb and yogurt marinated tandoori grilled pork fillet pieces in a ginger, tomato, and spice sauce" },
      { "id": 8, "nimi": "FISH FRY", "tyyppi": "(L)", "ainesosat": "Syväpaistettuja kalapaloja", "eng_description": "Deep-fried fish pieces" }
    ]
  },
  {
    "päivä": "Tiistai",
    "tuotteet": [
      { "id": 1, "nimi": "BASMATIRIISI", "tyyppi": "(L.G,Vegaani)", "ainesosat": "", "eng_description": "" },
      { "id": 2, "nimi": "KEITETYT KASVIKSET", "tyyppi": "(L.G,Vegaani)", "ainesosat": "", "eng_description": "" },
      { "id": 3, "nimi": "VEG PAKODA", "tyyppi": "(L.G,Vegaani)", "ainesosat": "Paistettuja ja maustettuja kasviksia", "eng_description": "Fried and spiced vegetables" },
      { "id": 4, "nimi": "MALAI KOFTA", "tyyppi": "(G)", "ainesosat": "Kasvispyöryköitä perunasta, cashew-pähkinästä, rusinoista ja tuorejuustosta tomaatti-, voi-, kermaisessa kastikkeessa", "eng_description": "Vegetable balls made from potatoes, cashew nuts, raisins, and fresh cheese in a tomato, butter, and creamy sauce" },
      { "id": 5, "nimi": "TOFU CHILI", "tyyppi": "(L.G,Vegaani)", "ainesosat": "Paistettuja tofupaloja sipulin, inkiväärin, paprikan, soijan ja chilin kastikkeessa", "eng_description": "Fried tofu pieces in an onion, ginger, bell pepper, soy, and chili sauce" },
      { "id": 6, "nimi": "CHICKEN KORMA", "tyyppi": "(L,G)", "ainesosat": "Haudutettuja kananfileepaloja tomaatti-, sipuli-, voi- ja kermaisessa kastikkeessa", "eng_description": "Stewed chicken fillet pieces in a tomato, onion, butter, and creamy sauce" },
      { "id": 7, "nimi": "PORK SADEKO", "tyyppi": "(L,G)", "ainesosat": "Marinoituja ja tandoori-uunissa grillattuja possua valkosipulissa, inkiväärissä, kuminassa ja korianterinsiemenissä", "eng_description": "Marinated and tandoori grilled pork in garlic, ginger, cumin, and coriander seeds" }
    ]
  },
  {
    "päivä": "Keskiviikko",
    "tuotteet": [
      { "id": 1, "nimi": "BASMATIRIISI", "tyyppi": "(L.G,Vegaani)", "ainesosat": "", "eng_description": "" },
      { "id": 2, "nimi": "KEITETYT KASVIKSET", "tyyppi": "(L.G,Vegaani)", "ainesosat": "", "eng_description": "" },
      { "id": 3, "nimi": "NUUDELIT", "tyyppi": "(L,Vegaani)", "ainesosat": "Paistettuja nuudeleita ja vihanneksia soijakastikkeella", "eng_description": "Fried noodles and vegetables with soy sauce" },
      { "id": 4, "nimi": "DAL KOFTA", "tyyppi": "(L.G,Vegaani)", "ainesosat": "Syväpaistettuja linssipalloja mausteiden kanssa", "eng_description": "Deep-fried lentil balls with spices" },
      { "id": 5, "nimi": "CHANA MASALA", "tyyppi": "(L.G,Vegaani)", "ainesosat": "Haudutettuja kikherneitä inkiväärin ja korianterin kanssa tomaatti- ja maustekastikkeessa", "eng_description": "Stewed chickpeas with ginger and coriander in a tomato and spice sauce" },
      { "id": 6, "nimi": "AKHANDA SPECIAL CHICKEN", "tyyppi": "(G)", "ainesosat": "Yrtti- ja jogurttimarinoituja tandoori-uunissa grillattuja kananfileepaloja sipulin, kookoskerman, juuston, voin ja kermaisen kastikkeen kanssa", "eng_description": "Herb and yogurt marinated tandoori grilled chicken fillet pieces in an onion, coconut cream, cheese, butter, and creamy sauce" },
      { "id": 7, "nimi": "LAMB KADAI", "tyyppi": "(L,G)", "ainesosat": "Haudutettuja lampaanpalasia sipulin, inkiväärin, paprikan, tomaatin, valkosipulin, korianterinsiementen ja kermaisen kastikkeen kanssa", "eng_description": "Stewed lamb pieces in an onion, ginger, bell pepper, tomato, garlic, coriander seeds, and creamy sauce" },
      { "id": 8, "nimi": "PRAWN CHILI", "tyyppi": "(L,G)", "ainesosat": "Paistettuja katkarapuja ja paprikoita tulisessa tomaatti-, sipuli-, chilin ja soijakastikkeessa", "eng_description": "Fried prawns and bell peppers in a spicy tomato, onion, chili, and soy sauce" }
    ]
  },
  {
    "päivä": "Torstai",
    "tuotteet": [
      { "id": 1, "nimi": "BASMATIRIISI", "tyyppi": "(L.G,Vegaani)", "ainesosat": "", "eng_description": "" },
      { "id": 2, "nimi": "KEITETYT KASVIKSET", "tyyppi": "(L.G,Vegaani)", "ainesosat": "", "eng_description": "" },
      { "id": 3, "nimi": "VEG SPRING ROLL", "tyyppi": "(L,Vegaani)", "ainesosat": "Syväpaistettuja rullia, jotka on täytetty vihanneksilla", "eng_description": "Deep-fried rolls filled with vegetables" },
      { "id": 4, "nimi": "SAG PANEER", "tyyppi": "(G)", "ainesosat": "Syväpaistettua tuorejuustoa ja pinaattia sipulin, valkosipulin, inkiväärin ja kermaisen kastikkeen kanssa", "eng_description": "Deep-fried fresh cheese and spinach in an onion, garlic, ginger, and creamy sauce" },
      { "id": 5, "nimi": "ALU GOBI MATAR", "tyyppi": "(L.G,Vegaani)", "ainesosat": "Haudutettuja perunoita, herneitä ja kukkakaalia sipulin, tomaatin, inkiväärin ja maustekastikkeen kanssa", "eng_description": "Stewed potatoes, peas, and cauliflower in an onion, tomato, ginger, and spice sauce" },
      { "id": 6, "nimi": "BUTTER CHICKEN", "tyyppi": "(L,G)", "ainesosat": "Yrtti- ja jogurttimarinoituja tandoori-uunissa grillattuja kananfileepaloja tomaatti-, voin ja kermaisen kastikkeen kanssa", "eng_description": "Herb and yogurt marinated tandoori grilled chicken fillet pieces in a tomato, butter, and creamy sauce" },
      { "id": 7, "nimi": "PORK CHILI", "tyyppi": "(L,G)", "ainesosat": "Possun paloja tomaatti-, sipuli-, paprikan, chilin ja soijakastikkeen kanssa", "eng_description": "Pork pieces in a tomato, onion, bell pepper, chili, and soy sauce" },
      { "id": 8, "nimi": "CHICKEN PAKODA", "tyyppi": "(L,G)", "ainesosat": "Syväpaistettuja ja mausteilla päällystettyjä kananpaloja", "eng_description": "Deep-fried and spiced chicken pieces" }
    ]
  },
  {
    "päivä": "Perjantai",
    "tuotteet": [
      { "id": 1, "nimi": "BASMATIRIISI", "tyyppi": "(L.G,Vegaani)", "ainesosat": "", "eng_description": "" },
      { "id": 2, "nimi": "KEITETYT KASVIKSET", "tyyppi": "(L.G,Vegaani)", "ainesosat": "", "eng_description": "" },
      { "id": 3, "nimi": "ONION PAKODA", "tyyppi": "(L.G,Vegaani)", "ainesosat": "Syväpaistettuja ja mausteilla päällystettyjä sipuleita", "eng_description": "Deep-fried and spiced onions" },
      { "id": 4, "nimi": "CHICKEN TIKKA MASALA", "tyyppi": "(L,G)", "ainesosat": "Yrtti- ja jogurttimarinoituja tandoori-uunissa grillattuja kananpaloja tomaatti-, sipuli- ja maustekastikkeessa", "eng_description": "Herb and yogurt marinated tandoori grilled chicken pieces in a tomato, onion, and spice sauce" },
      { "id": 5, "nimi": "LAMB KOFTA", "tyyppi": "(L,G)", "ainesosat": "Jauhettua lampaanlihaa tomaatti-, sipuli-, inkivääri- ja maustekastikkeessa", "eng_description": "Ground lamb meat in a tomato, onion, ginger, and spice sauce" },
      { "id": 6, "nimi": "SHAHI PANEER", "tyyppi": "(G)", "ainesosat": "Syväpaistettua tuorejuustoa tomaatti-, voin ja kermaisen kastikkeen kanssa", "eng_description": "Deep-fried fresh cheese in a tomato, butter, and creamy sauce" },
      { "id": 7, "nimi": "TOFU CHILI", "tyyppi": "(L.G,Vegaani)", "ainesosat": "Syväpaistettuja tofupaloja sipulin, inkiväärin, paprikan, soijan ja chilin kastikkeessa", "eng_description": "Deep-fried tofu pieces in an onion, ginger, bell pepper, soy, and chili sauce" }
    ]
  }
]



export default LunchItems
