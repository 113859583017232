import React from 'react'
import { SubHeading } from '../../components'
import "./LeftHeader.css"
import { images } from '../../constants'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

const LeftHeader = () => {
    const navigate = useNavigate()
    const inEnglish = useSelector(state => state.inEnglish)

    return (
        <div>
            <div  className='app__header-image1'>
                <img src={images.biryani} />
            </div>
            <div  className='app__header-image2'>
                <img src={images.chicken} />
            </div>
            <div className='app__header-cooking'>
                    <img src={images.chef_cooking} />
                </div>
            <div className='app__header app__wrapper section__padding ' id=''>

                
                <div className='app__wrapper_info'>
                    <SubHeading title="Chase the new Flavor" />
                    <h1  className='app__header-h1'>{inEnglish?"Welcome":"Tervetuloa"}</h1>
                    <p  className='p__opensans' style={{ margin: "2rem 0" }}>{inEnglish?"At Akhanda, We Offer Delicious Taste Experiences Made From Fresh Nepalese Ingredients. Check Out Our Versatile Menu And Book Your Table Today To Ensure An Unforgettable Dining Experience! Akhanda.":"Me Akhandassa tarjoamme herkullisia makuelämyksiä tuoreista nepalilaisista raaka-aineista valmistettuna. Tutustu monipuoliseen ruokalistaamme ja varaa pöytäsi jo tänään unohtumattoman ruokaelämyksen varmistamiseksi! Akhanda."}</p>
                    <button data-aos="fade-up" className='custom__button' onClick={() => navigate("/alacarte")}>A-La-Carte</button>
                </div>

            </div>

        </div>

    )
}

export default LeftHeader