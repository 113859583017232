import React from 'react'
import "./Cards.css"
import $ from "jquery";
import sectionMeal from '../../constants/Sections';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';



const Cards = () => {
   const navigate = useNavigate()
   const inEnglish = useSelector(state => state.inEnglish)
   return (
      <div>
         <div class="cards">
            <h1 data-aos="zoom-out-right" className='headtext__cormorant'>{inEnglish?"Menu":"RUOKALISTA"}</h1>
            {sectionMeal && sectionMeal.map((item, index) => {
               return (
                  <a data-aos="fade-up" class="card" href={item.section === "OLUET,SIIDERIT,LONKEROT" ? "alacarte/#olut" : `alacarte/#${item.section}`} onClick={() => window.location.reload()} >
                     <div class="card__image-holder">
                        <img class="card__image" src={item.image} alt="wave" />
                     </div>
                     <div class="card-title">

                        <div>
                           <h2>
                              {item.section}
                           </h2>
                        </div>
                     </div>
                  </a>
               )
            })}




         </div>




      </div>
   )
}

export default Cards
