const sectionMeal = [
  {
    "section": "ALKURUOAT-STARTER",
    "description": "",
    "image": "https://media.istockphoto.com/id/910844496/fi/valokuva/kasvissamsa.jpg?s=2048x2048&w=is&k=20&c=iTlUw1mIC0CiL6VUL75xmGbAfznU4aCUUgW1_GhWQyE="
  },
  {
    "section": "TANDOORI",
    "description": "",
    "image": "https://media.istockphoto.com/id/618457090/fi/valokuva/voikana-tarjoillaan-kotitekoisen-intialaisen-naan-leiv%C3%A4n-kanssa.jpg?s=2048x2048&w=is&k=20&c=itcTLD5ka4M8UPtZ82e8aMZ9iHPOgCCU1rHcKYYC2hg="
  },
  {
    "section": "KANA-CHICKEN",
    "description": "À la Carté annokseen sisältyy basmatiriisi, naan-leipä, papad-leipä, salaatti, chutny ja raita",
    "image": "https://media.istockphoto.com/id/1058029220/fi/valokuva/tandoori-kana.jpg?s=2048x2048&w=is&k=20&c=MsSskaPDsbXTTAAtbSIyKPvIusaHWnJAo7e8POP-OgM="
  },
  {
    "section": "KASVIS-VEGETARIAN",
    "description": "À la Carté annokseen sisältyy basmatiriisi, naan-leipä, papad-leipä, salaatti, chutny ja raita",
    "image": "https://media.istockphoto.com/id/1166167732/fi/valokuva/palak-paneer-harmaalla-betonitaustalla.jpg?s=2048x2048&w=is&k=20&c=F0vmjw_TyFMimBAxd92SuJmiL-nefMgoWny3KXOT604="
  },
  {
    "section": "LAMMAS-LAMB",
    "description": "À la Carté annokseen sisältyy basmatiriisi, naan-leipä, papad-leipä, salaatti, chutny ja raita",
    "image": "https://media.istockphoto.com/id/1150376626/fi/valokuva/lampaan-rogan-josh-intialainen-ruoka-kuparissa.jpg?s=2048x2048&w=is&k=20&c=ZWT6uGfJTu6fnGDlo1umjx4NDzXIAcOyxwSBolBPqxE="
  },
  {
    "section": "PORSAS-PORK",
    "description": "À la Carté annokseen sisältyy basmatiriisi, naan-leipä, papad-leipä, salaatti, chutny ja raita",
    "image": "https://media.istockphoto.com/id/160337271/fi/valokuva/goan-sianliha-vindaloo.jpg?s=2048x2048&w=is&k=20&c=jcN-7ZPlpZ8gW80QTfiUdlbd7W0TdF1Mt_n1XSvzJrs="
  },
  {
    "section": "KALARUOAT-SEA MEAL",
    "description": "À la Carté annokseen sisältyy basmatiriisi, naan-leipä, papad-leipä, salaatti, chutny ja raita",
    "image": "https://media.istockphoto.com/id/968502384/fi/valokuva/intialaista-ruokaa-tai-intialaista-currya-kupari-messinkikulhossa.jpg?s=2048x2048&w=is&k=20&c=fpwG9ZvmPvIEyu3cQd6L9UjahVDkUKOJHOB-MX6vD0Q="
  },
  {
    "section": "Akhanda Special Thali",
    "description": "À la Carté annokseen sisältyy basmatiriisi, naan-leipä, papad-leipä, salaatti, chutny ja raita",
    "image": "https://st2.depositphotos.com/5653638/11520/i/450/depositphotos_115207410-stock-photo-indian-thali-indian-food-thali.jpg"
  },
  {
    "section": "LASTENRUOAT",
    "description": "À la Carté annokseen sisältyy basmatiriisi, naan-leipä, papad-leipä, salaatti, chutny ja raita",
    "image": "https://media.istockphoto.com/id/658913004/fi/valokuva/family-having-food-at-table-in-dining-room.jpg?s=2048x2048&w=is&k=20&c=zd2e9MpS9r1zl6AD_w9v-c58Qigv5jzjjD7ZB5pn5og="
  },
  {
    "section": "LISUKKEET-SIDE ORDER",
    "description": "",
    "image": "https://media.istockphoto.com/id/183564340/fi/valokuva/l%C3%A4hikuva-kasasta-tavallisia-naan-kakkuja.jpg?s=2048x2048&w=is&k=20&c=34Av1CxYsiurqbOtwric0DwRZin0VoDjKKHwhEY2hdc="
  },
  {
    "section": "JÄLKIRUOAT",
    "description": "",
    "image": "https://media.istockphoto.com/id/1295976595/fi/valokuva/paistava-gulab-jamuun.jpg?s=2048x2048&w=is&k=20&c=DvYsCkUIAzFJpC97bOizGkv8pNMMOkQLAg5tIJVfxP4="
  },
  {
    "section": "JUOMAT-DRINKS",
    "description": "",
    "image": "https://media.istockphoto.com/id/600149904/fi/valokuva/kotitekoinen-intialainen-makea-chai.jpg?s=2048x2048&w=is&k=20&c=ffUOWYaP1-pkgfv6uiU6fUQicVM5f3KumcKeNCt6Fag="
  },
  {
    "section": "BIRYANI",
    "description": "",
    "image": "https://images.unsplash.com/photo-1512058564366-18510be2db19?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    "section": "MOMO",
    "description": "",
    "image": "https://media.istockphoto.com/id/1459900489/fi/valokuva/kiinalainen-ruoka-nyytti-momo.jpg?s=2048x2048&w=is&k=20&c=lzTnr4Fc7v8Vfrk-KFvkGlhG1glLii6ZCrPFHkRLWuM="
  },
  
  {
    "section": "OLUET,SIIDERIT,LONKEROT",
    "description": "",
    "image": "https://media.istockphoto.com/id/1060456922/fi/valokuva/kippis-juhla-paahtoleip%C3%A4%C3%A4-tuopillisella-olutta.jpg?s=2048x2048&w=is&k=20&c=--ma_4CqCm8kfpV-HxnaXb4GjwMVsNEH9zBq8GL3X9I="
  },
  
 
  
]

export default sectionMeal