import React from 'react';
import "./SuceesfullyOrder.css";
import { MenuItem, Navbar } from '../components';
import { Footer } from '../container';
import { useSelector } from 'react-redux';

const SuccessfullyOrderPage = () => {
  const booking = useSelector(state => state.booking)
  return (
    <>
      <Navbar />

      <div className='app_sucessfully_order'>
        <div className='app_successful'>
          <div className="app_successfull_card">
            <div
              className='app_written'
              style={{
                borderRadius: '200px',
                height: '200px',
                width: '200px',
                background: '#F8FAF5',
                margin: '0 auto'
              }}
            >
              <i className="checkmark">✓</i>
            </div>
            <h1>Kiitos.</h1>
            {
              booking &&
              booking ?<p>Varaus on tehty onnistuneesti. Saat vahvistussähköpostin</p>:
              <p>Tilauksesi on tehty onnistuneesti. Saat vahvistussähköpostin!</p>
            }
            
          </div>
        </div>
      </div>
      <MenuItem />
      <Footer />
    </>

  );
}

export default SuccessfullyOrderPage;
