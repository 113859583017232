import React from "react";
import { MenuItem, Navbar } from "../components";
import { Footer, Header, Intro, SpecialMenu } from "../container";
import Meals from "../container/ThePortions/Meals";
import OpeningTime from "../components/Weekupdate/OpeningTime";
import Contact from "./Contact";
import EventNotice from "../container/ThePortions/EventNotice";
const MainMenu = () => {
  return (
    <div>
      <Navbar />
      <EventNotice />
      <SpecialMenu />
      <OpeningTime />
      <MenuItem />
      <Footer />
    </div>
  );
};

export default MainMenu;
