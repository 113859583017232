import React, { useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import galleryimages from '../../constants/galleryimages'; // Assuming you've correctly imported galleryimages
import { Box, Dialog, useMediaQuery } from '@mui/material';
import { images } from '../../constants';
import "./Gallery.css"
import { useSelector } from 'react-redux';

export default function Gallery() {
    const [open, setOpen] = useState(false)
    const size = useMediaQuery('(min-width:600px)');
    const inEnglish = useSelector(state => state.inEnglish)
    const handleClose = () => {
        setOpen(true)
    }
    return (
        <div data-aos="zoom-out-right" style={{zIndex:0}}>
            
            <Box component="section" sx={{ p: 2, display: "flex", alignItems: "center", justifyContent: "center",flexDirection:"column"}}>
            <h1 data-aos="zoom-out-right" className='app__cards-h1-gallery app__meals-h1-gallery'>{inEnglish?"Gallery":"Galleriamme"}</h1>
                <ImageList sx={{ width: `${size ? "80%" : "95%"}`, height: '100% ', alignContent: "center" }} cols={size ? 3 : 2} gap={size ? 16 : 6} variant="masonry">
                    {galleryimages && galleryimages.map((item) => (

                        <ImageListItem  key={item}>
                            <img 
                                srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                alt={item}
                                loading="lazy"
                            />
                        </ImageListItem>


                    ))}
                </ImageList>
            </Box>
        </div>

    );
}
