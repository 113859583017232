import React from 'react';
import images from '../../constants/images';
import "./SubHeading.css";

const SubHeading = ({ title }) => (
  <div className='candle' style={{ marginBottom: "1rem" }}>
    <div class="container">
      <div class="red flame"></div>
      <div class="orange flame"></div>
      <div class="yellow flame"></div>
      <div class="white flame"></div>
      <div class="blue circle"></div>
      <div class="black circle"></div>
    </div>
    <p className='p__cormorant'>{title}</p>
    <img src={images.spoon} className='spoon__img' />
  </div>
);

export default SubHeading;
