import React, { useEffect, useState } from "react";
import "./EventNotice.css";
import { MdRestaurant } from "react-icons/md";
import axios from 'axios';
import { useSelector } from "react-redux";

const EventNotice = () => {
  const todayDate = new Date(Date.now());
  const [notice, setNotice] = useState(null)
  const inEnglish = useSelector(state => state.inEnglish)
  const isMayDay =
    (todayDate.getMonth() === 4 && todayDate.getDate() === 1) ||
    (todayDate.getMonth() === 3 && todayDate.getDate() === 30);

  const [fixed, setFixed] = useState(false);
  let classes = fixed ? "event__notice-sm fixed-cls" : "event__notice-sm";
  const fetchData = async () => {
    try {
      const response = await axios.get("http://localhost:3002/api/notice");
      console.log(response.data);
      setNotice(response.data[0])
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData()
  })

  

  const selectFixed = () => {
    if (window.scrollY >= 78) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };
  window.addEventListener("scroll", selectFixed);
  if (!notice){
    return (
      <div></div>
    )
  }
  return (
  
    <div>
    
      <div className={classes}>
        <p
          style={{
            fontSize: "10px",
            textAlign: "center",
            width: "100%",
          }}
        >

          <br />
          {inEnglish ? notice.engHeader : notice.header}
          <hr
            style={{
              color: "#ff8103",
              padding: "0px",
              margin: "0px",
              border: "solid 1px",
            }}
          />{" "}
         {inEnglish ? notice.engNotice : notice.message}
        </p>
      </div>
      <div className="event__notice-lg">
        <div className="marquee">
          <p style={{}}>
            <MdRestaurant
              color="#bcffbd"
              style={{ marginRight: "5px", marginLeft: "5px" }}
            />{inEnglish ? notice.engHeader : notice.header}
            <MdRestaurant
              color="#bcffbd"
              style={{ marginRight: "5px", marginLeft: "5px" }}
            />
              {inEnglish ? notice.engNotice : notice.message}
          

          </p>
        </div>
      </div> 
    </div> 
    
          )
};

export default EventNotice;
