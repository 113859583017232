const mainmenu = {
  "menu": [
    {
      "section": "ALKURUOAT-STARTER",
      "items": [
        {
          "name": "VEG SOUP (L,G,Vegan)",
          "price": 6.90,
          "description": "Kasviskeitto "
        },
        {
          "name": "PANEER TIKKA (G)",
          "price": 8.90,
          "description": "Marinoitua tuorejuustoa, vihreä paprikaa, sipulia ja tomaattia",
          "ingredients": "Marinated cottage cheese with capsicum, onion and tomato"
        },
        {
          "name": "TOMATO SOUP (L,G) / (Pyynnöstä vegaani)",
          "price": 6.90,
          "description": "Tomaattikeitto"
        },
        {
          "name": "CHICKEN SOUP (L,G)",
          "price": 7.50,
          "description": "Kanakeitto"
        },
        {
          "name": "HOT & SOUR SOUP (L,G)",
          "price": 8.50,
          "description": "Tulisesti maustettu sitruunanmakuinen keitto, jossa on jättikatkarapuja ja sieniä",
          "ingredients": "King prawns with mushroom in hot and spicy lemon flavour base"
        },
        {
          "name": "PANEER PAKODA (G)",
          "price": 7.90,
          "description": "Maustettuja, kikhernejauholla kuorrutettuja ja friteerattuja tuorejuustoa",
          "ingredients": "Deep fried and gram batter coated cottage cheese with spices"
        },
        {
          "name": "KING PRAWN PAKODA (L,G)",
          "price": 9.50,
          "description": "Maustettuja, kikhernejauholla kuorrutettuja ja friteerattuja jättikatkarapuja",
          "ingredients": "Deep fried and gram batter coated king prawns with spices"
        },
        {
          "name": "MIX CAESAR SALAD",
          "price": 10.50,
          "description": "Salaattia caesarkastikkeen, kanan, katkaravun ja oliivien kera",
          "ingredients": "Salad with caesar sauce, chicken, prawns and olives"
        },
        {
          "name": "CHICKEN SALAD (L,G)",
          "price": 8.90,
          "description": "Marinoitua ja tandoorissa grillattua kanaa, salaattia, kurkkua, tomaattia, oliivia ja paprikaa",
          "ingredients": "Marinated and tandoor grilled chicken pieces with lettuce, cucumber, tomato, olive and capsicum"
        }
      ]
    },
    {
      "section": "TANDOORI",
      "note": "A la Carte annokseen sisåltyy basmatiirisi, naan-leipå, papad-leipå, salaatti, chutny ja raita / A la Carte dish includes basmati rice, naan bread, papadum, salad, chutny and raita",
      "items": [
        {
          "name": "CHICKEN TIKKA",
          "price": 18.50,
          "type": "L, G",
          "description": "Herb-yoghurt marinated tandoor oven grilled chicken in tomato, onion and masala sauce"
        },
        {
          "name": "CHICKEN SIRAZ",
          "price": 18.90,
          "type": "L, G",
          "description": "Yrtti-jogurttimarinoituja-ja-tandoori-uunissa-grillattuja-kanafilee-paloja-sipulia-ja-paprikaa-curry-ja-kermakastikkeessa"
        },
        {
          "name": "MALAI TIKKA",
          "price": 21.90,
          "type": "L, G",
          "description": "Yrtti-jogurttimarinoituja-ja-tandoori-uunissa-grillattuja-kanafilee-paloja-kookos-cashew-ja kermakastikkeessa"
        },
        {
          "name": "FISH TIKKA",
          "price": 19.90,
          "type": "L, G",
          "description": "Yrtti-jogurtissamarinoituja-ja-tandoorissa-grillattuja-kalapaloja-sekä-paprikaa-ja-sipulia-jogurttikastikkeessa"
        },
        {
          "name": "TANDOORI MIX",
          "price": 22.50,
          "type": "L, G",
          "description": "Yrtti-jogurttimarinoituja-ja-tandoori-uunissa-grillattuja-lampaan-sisäfilee-paloja-kana-rintafile-paloja-ja-jättikatkarapu-paprika-tomaatti-inkivääri-valkosipuli-ja-jogurttikastikkeessa"
        },
        {
          "name": "TANDOORI KING PRAWN",
          "price": 23.50,
          "type": "L, G",
          "description": "Yrtti-jogurtissamarinoituja-ja-tandoorissa-grillattuja-jättikatkarapuja-sekä-paprikaa-ja-sipulia-jogurttikastikkeessa"
        },{
          "name": "GARLIC TIKKA (L,G)",
          "price": 21.90,
          "description": "Yrtti-jogurttimarinoituja-ja-tandoori-uunissa-grillattuja-kanaa-sipulia-ja-paprikaa-jogurtti-ja-valkosipulikastikkeessa"
        },
        {
          "name": "PESHAWARI TIKKA (L,G)",
          "price": "22,50€",
          "description": "Yrtti-jogurtissamarinoituja-ja-tandoorissa-grillattuja-kanafilee-paloja-paprikaa-ja-sipulia-peshawarikastikkeessa"
        },
        {
          "name": "PORK TIKKA (L,G)",
          "price": "20,90€",
          "description": "Yrtti-jogurttimarinoituja ja tandoori-uunissa grillattuja porsaan paloja, paprikaa ja sipulia peshawarikastikkeessa Herb and yoghurt marinated tandoor oven grilled pork pieces, capsicum and onion in peshawari sauce"
        },
        {
          "name": "AKHANDA SPECIAL SIZZLER (G)",
          "price": "26,90€",
          "description": "Yrtti-jogurttimarinoituja ja tandoori-uunissa grillattuja kanan fileepaloja sekä lampaan paloja, tuorejuustoa, lampaan jauhelihavartaita ja jättikatkarapuja paprika-, tomaatti-, inkivääri-, valkosipuli- ja jogurttikastikkeessa"
        }

      ]
    },
    {
      "section": "KANA-CHICKEN",
      "items": [
        {
          "name": "CHICKEN CHILI (L,G)",
          "price": "17,90€",
          "description": "Paistettuja kanafilee paloja tomaatti-, sipuli-, paprika-, chili- ja soijakastikkeessa",
          "ingredients": "Fried chicken fillet pieces in tomato, onion, capsicum, chili and soya sauce"
        },
        {
          "name": "SAG CHICKEN (L,G)",
          "price": "17,50€",
          "description": "Haudutettuja kanafilee paloja tomaatti-, sipuli-, valkosipuli-, kerma- ja pinaattikastikkeessa",
          "ingredients": "Stewed chicken fillet pieces in tomato, onion, garlic, cream and spinach sauce"
        },
        {
          "name": "CHICKEN KORMA (L,G)",
          "price": "17,50€",
          "description": "Haudutettuja kanafilee paloja tomaatti-, sipuli-, voi- ja kermakastikkeessa",
          "ingredients": "Stewed chicken fillet pieces in tomato, onion, butter and cream sauce"
        },
        {
          "name": "BUTTER CHICKEN (L,G)",
          "price": "17,90€",
          "description": "Yrtti-jogurttimarinoituja ja tandoori-uunissa grillattuja kanan fileepaloja tomaatti-, voi- ja kermakastikkeessa",
          "ingredients": "Herb and yoghurt marinated tandoor oven grilled chicken fillet pieces in tomato, butter and cream sauce"
        },
        {
          "name": "CHICKEN BUTTER MASALA (L,G)",
          "price": "18,90€",
          "description": "Yrtti-jogurttimarinoituja ja tandoori-uunissa grillattuja kanafilee paloja tomaatti-, sipuli-, cashew-, kookos-, voi- ja kermakastikkeessa",
          "ingredients": "Herb and yoghurt marinated tandoor oven grilled chicken in tomato, onion, cashew, coconut, butter & cream sauce"
        },
        {
          "name": "AKHANDA SPECIAL CHICKEN (G)",
          "price": "19,90€",
          "description": "Yrtti-jogurttimarinoituja ja tandoori-uunissa grillattuja kanafilee paloja tomaatti-, sipuli-, cashew-, kookos-, voi-, juusto- ja kermakastikkeessa",
          "ingredients": "Herb-yoghurt marinated tandoor chicken fillet pcs in tomato, onion, cashew, coconut, butter, cheese & cream sauce"
        },
        {
          "name": "CHICKEN VINDALOO (L,G)",
          "price": "17,50€",
          "description": "Haudutettuja kanafilee paloja ja perunaa chili-, tomaatti-, sipuli- ja vindalookastikkeessa",
          "ingredients": "Stewed chicken fillet pieces and potatoes in chili, tomato, onion and vindaloo sauce"
        },
        {
          "name": "CHICKEN KARAI (L,G)",
          "price": "17,90€",
          "description": "Haudutettuja kanafilee paloja tomaatti-, sipuli-, paprika-, masala-, kerma- ja currykastikkeessa",
          "ingredients": "Stewed chicken fillet pieces in tomato, onion, capsicum, masala, cream and curry sauce"
        },
        {
          "name": "MISMAS KARAI (G)",
          "price": "20,50€",
          "description": "Yrtti-jogurttimarinoituja ja tandoori-uunissa grillattuja kanafilee paloja sekä lampaan paloja ja tuorejuustoa tomaatti-, sipuli- ja masalakastikkeessa",
          "ingredients": "Herb-yoghurt marinated tandoor oven grilled chicken with lamb and cottage cheese in tomato, onion & masala sauce"
        },
        {
          "name": "CHICKEN TIKKA MASALA (L,G)",
          "price": "18,50€",
          "description": "Yrtti-jogurttimarinoituja ja tandoori-uunissa grillattuja kanafilee paloja tomaatti-, sipuli- ja masalakastikkeessa",
          "ingredients": "Herb-yoghurt marinated tandoor oven grilled chicken in tomato, onion and masala sauce"
        },
        {
          "name": "COCONUT CHICKEN (L,G)",
          "price": "17,50€",
          "description": "Haudutettuja kanafilee paloja tomaatti-, sipuli- ja kookoskermakastikkeessa",
          "ingredients": "Stewed chicken fillet pieces in tomato, onion and coconut cream sauce"
        }
      ]
    },
    ,{
      "section": "KASVIS-VEGETARIAN",
      "items": [
        {
          "name": "ALU GOBI (L,G,Vegan)",
          "price": "14,90€",
          "description": "Keitetty peruna ja kukkakaali sipuli-, valkosipuli-, inkivääri- ja currykastikkeessa",
          "ingredients": "Stewed potatoes and cauliflower in onion, garlic, ginger and curry sauce"
        },
        {
          "name": "BHINDI MASALA (L,G,Vegan)",
          "price": "15,50€",
          "description": "Ruskistettua okraa, paprikaa ja perunaa sipuli-, tomaatti-, inkivääri- ja masalakastikkeessa",
          "ingredients": "Sautéed okra with capsicum and potatoes in onion, tomato, ginger and masala sauce"
        },
        {
          "name": "TOFU MASALA (L,G,Vegan)",
          "price": "15,50€",
          "description": "Friteerattuja tofupaloja paprika-, sipuli-, inkivääri- ja masalakastikkeessa",
          "ingredients": "Deep fried tofu pieces in capsicum, onion, ginger and masala sauce"
        },
        {
          "name": "SAG TOFU (L,G,Vegan)",
          "price": "15,50€",
          "description": "Friteerattuja tofupaloja ja pinaattia sipuli-, valkosipuli-, inkivääri- ja currykastikkeessa",
          "ingredients": "Deep fried tofu pieces with spinach in onion, garlic, ginger and curry sauce"
        },
        {
          "name": "TOFU CHILI (L,G,Vegan)",
          "price": "15,50€",
          "description": "Friteerattuja tofupaloja sipuli-, inkivääri-, paprika-, soija- ja chilikastikkeessa",
          "ingredients": "Deep fried tofu pieces in onion, ginger, capsicum, soya and chili sauce"
        },
        {
          "name": "PALAK PANEER (G)",
          "price": "17,20€",
          "description": "Friteerattua tuorejuustoa ja pinaattia sipuli-, valkosipuli-, inkivääri- ja kermakastikkeessa",
          "ingredients": "Deep fried cottage cheese and spinach in onion, garlic, ginger and cream sauce"
        },
        {
          "name": "PANEER CHILI (G)",
          "price": "17,50€",
          "description": "Friteerattua tuorejuustoa sipuli-, inkivääri-, paprika-, soija- ja chilikastikkeessa",
          "ingredients": "Deep fried cottage cheese in onion, ginger, capsicum, soya and chili sauce"
        },
        {
          "name": "PANEER MAKHANI (G)",
          "price": "18,50€",
          "description": "Leipäjuustoa tomaatti-, voi- ja kermakastikkeessa",
          "ingredients": "Finnish squeaky cheese in tomato, butter and cream sauce"
        },
        {
          "name": "PANEER BUTTER MASALA (G)",
          "price": "18,90€",
          "description": "Friteerattua tuorejuustoa sipuli-, tomaatti-, cashew-, voi-, kerma- ja masalakastikkeessa",
          "ingredients": "Deep fried cottage cheese in onion, tomato, cashew, butter, cream and masala sauce"
        },
        {
          "name": "PANEER PASANDA (G)",
          "price": "18,90€",
          "description": "Friteerattua tuorejuustoa sipuli-, tomaatti-, cashew-, kookos- ja kermakastikkeessa",
          "ingredients": "Deep fried cottage cheese in onion, tomato, cashew, coconut and cream sauce"
        },
        {
          "name": "MATAR PANEER (G)",
          "price": "17,50€",
          "description": "Tuorejuustoa ja herneitä sipuli-, valkosipuli-, inkivääri-, tomaatti- ja kermakastikkeessa",
          "ingredients": "Cottage cheese and peas in onion, garlic, ginger, tomato and cream sauce"
        },
        {
          "name": "MALAI KOFTA (G)",
          "price": "17,90€",
          "description": "Kasvispyöryköitä (peruna, cashew, rusinaa ja tuorejuustoa) tomaatti-, voi- ja kermakastikkeessa",
          "ingredients": "Vegetable balls made of potato, cashew, raisins & cottage cheese in tomato, butter & cream sauce"
        },
        {
          "name": "SHAHIPANEER (G)",
          "price": "18,50€",
          "description": "Friteerattua tuorejuustoa tomaatti-, voi- ja kermakastikkeessa",
          "ingredients": "Deep fried cottage cheese in tomato, butter and cream sauce"
        },
        {
          "name": "VEG KOFTA (L,G)",
          "price": "17,50€",
          "description": "Kasvispyöryköitä tomaatti-, sipuli- ja kermakastikkeessa",
          "ingredients": "Vegetable balls in tomato, onion and cream sauce"
        },
        {
          "name": "DAL MAKHANI (L,G)",
          "price": "17,50€",
          "description": "Haudutettuja linssipapuja tomaatti-, voi- ja kermakastikkeessa",
          "ingredients": "Stewed black lentils in tomato, butter and cream sauce"
        },
        {
          "name": "PANEER KARAI (G)",
          "price": "17,90€",
          "description": "Tuorejuustopaloja tomaatti-, sipuli-, paprika-, masala-, kerma- ja currykastikkeessa",
          "ingredients": "Cottage cheese pieces in tomato, onion, capsicum, masala, cream and curry sauce"
        }
      ]
    },
    {
      "section": "LAMMAS-LAMB",
      "items": [
        {
          "name": "LAMB CHILI (L,G)",
          "price": "17,90€",
          "description": "Haudutettuja lampaan paloja ja paprikaa tomaatti-, sipuli-, chili- ja soijakastikkeessa",
          "ingredients": "Stewed lamb pieces and capsicum in tomato, onion, chili and soya sauce"
        },
        {
          "name": "SAG LAMB (L,G)",
          "price": "17,90€",
          "description": "Haudutettuja lampaan paloja tomaatti-, sipuli-, valkosipuli-, kerma- ja pinaattikastikkeessa",
          "ingredients": "Stewed lamb pieces in tomato, onion, garlic, cream and spinach sauce"
        },
        {
          "name": "LAMB KORMA (L,G)",
          "price": "17,50€",
          "description": "Haudutettuja lampaan paloja tomaatti-, sipuli-, voi- ja kermakastikkeessa",
          "ingredients": "Stewed lamb pieces in tomato, onion, butter and cream sauce"
        },
        {
          "name": "LAMB KOFTA (L,G)",
          "price": "17,50€",
          "description": "Lampaan jauhelihapyöryköitä tomaatti-, sipuli-, inkivääri- ja masalakastikkeessa",
          "ingredients": "Minced lamb meat balls in tomato, onion, ginger and masala sauce"
        },
        {
          "name": "LAMB BUTTER MASALA (L,G)",
          "price": "19,50€",
          "description": "Haudutettuja lampaan paloja tomaatti-, cashew-, kookos-, voi- ja kermakastikkeessa",
          "ingredients": "Stewed lamb pieces in tomato, cashew, coconut, butter and cream sauce"
        },
        {
          "name": "LAMB TAWA MASALA (L,G)",
          "price": "19,50€",
          "description": "Haudutettuja lampaan paloja tomaatti-, sipuli-, paprika-, kerma- ja masalakastikkeessa",
          "ingredients": "Stewed lamb pieces in tomato, onion, capsicum, cream and masala sauce"
        },
        {
          "name": "LAMB VINDALOO (L,G)",
          "price": "18,50€",
          "description": "Haudutettuja lampaan paloja ja perunaa tomaatti-, sipuli- ja vindalookastikkeessa",
          "ingredients": "Stewed lamb pieces and potatoes in tomato, onion and vindaloo sauce"
        },
        {
          "name": "LAMB KABAB (L,G)",
          "price": "17,90€",
          "description": "Yrtti-jogurtissa marinoidut ja tandoori uunissa grillatut lampaan jauhelihavartaat ja ruskistettu paprika tulisessa tomaatti-, valkosipuli-, kananmuna-, jogurtti- ja masalakastikkeessa",
          "ingredients": "Herb & yogurt marinated tandoor oven grilled minced lamb meat sticks and sauteed capsicum in spicy tomato, garlic, egg, yogurt and masala sauce"
        },
        {
          "name": "COCONUT LAMB (L,G)",
          "price": "17,90€",
          "description": "Haudutettuja lampaan paloja tomaatti-, sipuli- ja kookoskermakastikkeessa",
          "ingredients": "Stewed lamb pieces in tomato, onion and coconut cream sauce"
        }
      ]
    }, 
    {
      "section": "PORSAS-PORK",
      "items": [
        {
          "name": "PORK CURRY (L,G)",
          "price": 14.90,
          "description": "Porsaan paloja tomaatti-, sipuli- ja currykastikkeessa",
          "ingredients": "Pork pieces in tomato, onion and curry sauce"
        },
        {
          "name": "PORK KORMA (L,G)",
          "price": 16.50,
          "description": "Porsaan paloja tomaatti-, sipuli- ja kermakastikkeessa",
          "ingredients": "Pork pieces in tomato, onion and cream sauce"
        },
        {
          "name": "COCONUT PORK (L,G)",
          "price": 16.50,
          "description": "Porsaan paloja tomaatti-, sipuli- ja kookoskermakastikkeessa",
          "ingredients": "Pork pieces in tomato, onion and coconut cream sauce"
        },
        {
          "name": "BUTTER PORK (L,G)",
          "price": 16.50,
          "description": "Porsaan paloja tomaatti-, voi- ja kermakastikkeessa",
          "ingredients": "Pork pieces in tomato, butter and cream sauce"
        },
        {
          "name": "PORK TAWA MASALA (L,G)",
          "price": 16.90,
          "description": "Porsaan paloja ja paprikaa tomaatti-, sipuli- ja masalakastikkeessa",
          "ingredients": "Pork pieces and capsicum in tomato, onion and masala sauce"
        }
      ]
    },
    {
      "section": "KALARUOAT-SEA MEAL",
      "items": [
        {
          "name": "FISH CHILI (L,G)",
          "price": 17.90,
          "description": "Friteerattuja tilapian paloja ja paprikaa mausteisessa tomaatti-, sipuli-, chili- ja soijakastikkeessa",
          "ingredients": "Deep fried tilapia fish pieces and capsicum in spicy tomato, onion, chili and soya sauce"
        },
        {
          "name": "KING PRAWN KORMA (L,G)",
          "price": 18.90,
          "description": "Paistettuja jättikatkarapuja tomaatti-, sipuli- ja kermakastikkeessa",
          "ingredients": "Fried king prawn pieces in tomato, onion and cream sauce"
        },
        {
          "name": "KING PRAWN BUTTER MASALA (L,G)",
          "price": 19.90,
          "description": "Jättikatkarapuja tomaatti-, cashew-, sipuli-, kookos-, voi- ja kermakastikkeessa",
          "ingredients": "King prawn in tomato, cashew, onion, coconut, butter and cream sauce"
        },
        {
          "name": "KING PRAWN CHILI (L,G)",
          "price": 18.90,
          "description": "Paistettuja jättikatkarapuja ja paprikaa mausteisessa tomaatti-, sipuli-, chili- ja soijakastikkeessa",
          "ingredients": "Fried king prawn pieces and capsicum in spicy tomato, onion, chili and soya sauce"
        },
        {
          "name": "FISH BUTTER MASALA (L,G)",
          "price": 19.70,
          "description": "Friteerattuja tilapian paloja tomaatti-, cashew-, sipuli-, kookos-, voi- ja kermakastikkeessa",
          "ingredients": "Deep fried tilapia fish pieces in tomato, cashew, onion, coconut, butter and cream sauce"
        }
      ]
    },
    {
      "section": "Akhanda Special Thali",
      "items": [
        {
          "name": "87 VEG THALI",
          "price": "25,00€",
          "description": "Kolme ruokalajia thali-lautasella",
          "items": [
            {
              "id":"1",
              "name": "DAL MAKHANI",
              "type": "L, G",
              "description": "Mustalinssejä, tomaatteja ja mausteita kermassa"
            },
            {
              "id":"2",
              "name": "MIX VEG",
              "type": "L, G",
              "description": "Seoksesta vihanneksia ja mausteita"
            },
            {
              "id":"3",
              "name": "PANEER BUTTER MASALA",
              "type": "G",
              "description": "Paneeria kypsennetty voi- ja kermaisessa kastikkeessa"
            }
          ]
        },
        {
          "name": "88 MEAT THALI",
          "price": "27,00€",
          "description": "Kolme ruokalajia thali-lautasella",
          "items": [
            {
              "id":"1",
              "name": "LAMB CURRY",
              "type": "L, G",
              "description": "Lammasta kypsennetty currykastikkeessa"
            },
            {
              "id":"2",
              "name": "PRAWN KORMA",
              "type": "L, G",
              "description": "Katkarapuja kypsennetty kermakastikkeessa"
            },
            {
              "id":"3",
              "name": "CHICKEN BUTTER MASALA",
              "type": "L, G",
              "description": "Kanaa kypsennetty voi- ja kermaisessa kastikkeessa"
            }
          ]
        }
      ]
    },
    {
      "section": "LASTENRUOAT",
      "note": "A la Carte annokseen sisåltyy basmatiirisi, naan-leipå, papad-leipå, salaatti, chutny ja raita / A la Carte dish includes basmati rice, naan bread, papadum, salad, chutny and raita",
      "items": [
        {
          "name": "KANA RANSKALAISILLA",
          "price": 9.9,
          "type": "L, G",
          "description": "Korppujauholla kuorrutettuja ja friteerattuja kanasuikaleita ranskalaisilla"
        },
        {
          "name": "SHAHI PANEER",
          "price": "10,0",
          "type": "G",
          "description": "Friteerattuja tuorejuustoa tomaatti-, voi- ja kermakastikkeessa"
        },
        {
          "name": "KALA RANSKALAISILLA",
          "price": "10,0",
          "type": "G",
          "description": "Kalanugetteja ranskalaisilla"
        },
        {
          "name": "PALAK PANEER",
          "price": 10.5,
          "type": "G",
          "description": "Friteerattuja tuorejuustoa ja pinaattia sipuli-, valkosipuli-, inkivääri- ja currykastikkeessa"
        },
        {
          "name": "BUTTER CHICKEN",
          "price": 10.5,
          "type": "G",
          "description": "Yrtti-jogurttimarinoituja ja tandoori-uunissa grillattuja kanan fileepaloja tomaatti-, voi- ja kermakastikkeessa"
        },
        {
          "name": "PRAWN KORMA",
          "price": 10.5,
          "type": "G",
          "description": "Katkarapuja sipuli-, tomaatti-, voi- ja kermakastikkeessa"
        },
        {
          "name": "CHICKEN FRY RICE",
          "price": "10,0",
          "type": "L, G",
          "description": "Paistettua riisiä sekä kanaa kauden kasviksilla soijakastikkeessa"
        },
        {
          "name": "CHICKEN CURRY",
          "price": "10,0",
          "type": "L, G",
          "description": "Haudutettuja kanafilee paloja tomaatti-, sipuli- ja currykastikkeessa"
        }
      ]
    },
    {
      "section": "LISUKKEET-SIDE ORDER",
      "items": [
        {
          "name": "Papadum / Papad",
          "price": 1.5
        },
        {
          "name": "Tandoori naan",
          "price": 2.5
        },
        {
          "name": "Valkosipuli naan / Garlic naan",
          "price": 3.5
        },
        {
          "name": "Raitajogutti (cashew yoghurt sauce)",
          "price": 2.5
        },
        {
          "name": "Mix Pickle / Mint Chutney",
          "price": 2.9
        },
        {
          "name": "Riisi / Rice",
          "price": 3.9
        },
        {
          "name": "SAG PANEER",
          "price": 11.9,
          "type": "G",
          
        },
        {
          "name": "MALAI KOFTA",
          "price": 11.9,
          "type": "G",
          
        },
        {
          "name": "ALU GOBI",
          "price": 11.9,
          "type": "L, G, Vegan",
          
        },
        {
          "name": "CHICKEN CURRY",
          "price": 11.9,
          "type": "G, L",
          
        },
        {
          "name": "CHICKEN MASALA",
          "price": 11.9,
          "type": "G, L",
          
        }
        ,
        {
          "name": "LAMB CURRY",
          "price": 12.9,
          "type": "G, L",
          
        },
        {
          "name": "FISH CURRY",
          "price": 12.9,
          "type": "G, L",
          
        },
        {
          "name": "BUTTER CHICKEN",
          "price": 12.9,
          "type": "G",
          
        }


      ]
    },
    {
      "section": "JÄLKIRUOAT",
      "items": [
        {
          "name": "JAATELO PALLO / ICE CREAM",
          "price": 4.9,
          "type": "G",
          "description": "Vanilla or mango ice cream, sauce with own choice"
        },
        {
          "name": "KULFI",
          "price": 6.5,
          "type": "G",
          "description": "Nepalese sorbet made of pistachio and cashew flavored with saffron"
        },
        {
          "name": "AKHANDA BANANA",
          "price": 6.9,
          "type": "G",
          "description": "Fried banana, honey and ice cream with whipped cream"
        },
        {
          "name": "GULAB JAMUN",
          "price": 5.5,
          "type": "G",
          "description": "Delicious sweet made of milk & wheat flour with cardamom & rose water flavour"
        }
      ]
    },
    {
      
      "section": "BIRYANI",
      "items": [
        {
          "name": "VEG Biryani",
          "price": 13.9,
          "type": "L, G, Vegan",
          "description": "Paistetua riisiä ja tuorejuustoa kauden kasviksilla yrteillä maustettuna",
        },
        {
          "name": "CHICKEN BIRYANI",
          "price": 14.9,
          "type": "L, G",
          "description": "Paistettua riisiä kanaa ja kauden kasviksia yrteilä masutettuna"
        },
        {
          "name": "MIX BIRYANI",
          "price": 16.9,
          "type": "L, G",
          "description": "pasitettua riisiä kannaa, katkarapuja ja kauden kasviksia yrteillä masustettuna"
        }
      ]
    },
    {
      "section": "MOMO",
      "items": [
        {
          "name": "CHICKEN MOMO",
          "price": 14.9,
          "type": "L",
          "description": "Höyrystettyjä nyyttejä kanalla täytettynä"
        }
      ]
    },
    {
      "section": "JUOMAT-DRINKS",
      "items": [
        {
          "name": "Nepalilainen tee - Nepalese Tea",
          "price": 3.5,
          "description": "Inkiväärillä ja vihreällä kardemummalla masustettu tee , maidolla tai ilman"
        },
        {
          "name": "Nepalilainen kahvi - Nepalese Coffee",
          "price": 3.5,
          "description": "Inkiväärillä ja vihreällä kardemummalla masutettu maitokahvi"
        },
        {
          "name": "LASSI - (Banana tai Mangotai Strawberry)",
          "price": 5.0
        },
        {
          "name": "LASSI - (makea tai suolainen jogurttijooma / sweet or salty yoghurt drink)",
          "price": 4.5
        },    
        {
          "name": "VIRVOITUSJUOMAT(0.33l)",
          "description": "Pepsi, Pepsi Max, Jaffa, 7up, Vichy",
          "price": 3.2
        },
        {
          "name": "VIRVOITUSJUOMAT(0.50l)",
          "description": "Pepsi, Pepsi Max, Jaffa, 7up, Vichy",
          "price": 3.2
        },
        {
          "name": "MEHU / JUICE (0.33l)",
          "description": "Applesiinimehu, omenamenhu ,mangomehu, fresh lemon juice",
          "price": 3.9
        } 
      ]
    },
    {
      "section": "OLUET,SIIDERIT,LONKEROT",
      "items": [
        {
          "name": "Kathmandu, Nepalese Beer",
          "price": 9.5,
          "type": "G",
          "description": "4.8%, 0.56l pullo"
        },
        {
          "name": "Gurkha, Nepalese Beer",
          "price": 6.5,
          "type": "G",
          "description": "4.8%, 0.33l pullo"
        },
        {
          "name": "Nepal Ice, Nepalese Beer",
          "price": 6.9,
          "type": "G",
          "description": "5.5%, 0.33l pullo"
        },
        {
          "name": "Lapin Kulta IV",
          "price": 6.9,
          "type": "G",
          "description": "5.2%, 0.50l töilkki"
        },
        {
          "name": "Lapin Kulta",
          "price": 6.9,
          "type": "G",
          "description": "4.5%, 0.315l "
        },
        {
          "name": "Heineken",
          "price": 5.9,
          "type": "G",
          "description": "4.6%, 0.56l töilkki"
        },
        {
          "name": "Lapin Kulta (Gluten Free, Organic)",
          "price": 6.9,
          "type": "G",
          "description": "4.5%, 0.33l töilkki"
        },
        {
          "name": "Tumma Krusovice (Tsechi)",
          "price": 7.5,
          "type": "G",
          "description": "3.8%, 0.5l pullo"
        },
        {
          "name": "Krusovice Imperial",
          "price": 6.5,
          "type": "G",
          "description": "5%, 0.5 töilkki"
        },
        {
          "name": "Aura III",
          "price": 5.5,
          "type": "G",
          "description": "4.5%, 0.5l hanaolut(iso)"
        },
        {
          "name": "Aura III",
          "price": 5,
          "type": "G",
          "description": "4.5%, 0.33l Hanaolut(pieni)",
        },
        {
          "name": "Original Long Drink",
          "price": 6.5,
          "type": "G",
          "description": "5.5%, 0.33 bottle"
        },
        {
          "name": "Apple Dry, Perry Sweet",
          "price": 6.5,
          "type": "G",
          "description": "4.7%, 0.33l pullo"
        },
        {
          "name": "Up Cider (Pear)",
          "price": 6.5,
          "type": "G",
          "description": "4.7%, 0.33l pullo"
        }
      ]
    },
    
    
    
    
  ]
}

function convertPriceToString(menu) {
  // Iterate over each section in the mainmenu
  menu.menu.forEach(section => {
    // Iterate over each item in the section
    section.items.forEach(item => {
      // Check if the price is an array (for soft drinks)
      if (Array.isArray(item.price)) {
        // Convert each price in the array to string format with comma instead of dot
        item.price = item.price.map(price => (price !== undefined ? price.toString().replace('.', ',') : ''));
      } else {
        // Convert the price to string format with comma instead of dot
        item.price = item.price !== undefined ? item.price.toString().replace('.', ',') : '';
      }
    });
  });

  return menu;
}

// Call the function and store the result
const updatedMenu = convertPriceToString(mainmenu);




export default updatedMenu