import React, { useEffect, useState } from 'react';
import LunchTodayItem from './LunchTodayItem';
import { images } from '../../constants';
import "./TodayLunch.css";
import { useDispatch, useSelector } from 'react-redux';

const TodayLunch = () => {
    const [day, setDay] = useState(0);
    const dispatch = useDispatch();
    const inEnglish = useSelector(state => state.inEnglish);
    const lunch = useSelector(state => state.lunch);

    useEffect(() => {
        const date = new Date();
        const options = { timeZone: 'Europe/Helsinki' };
        const dayIndex = (new Date(date.toLocaleString('en-US', options)).getDay() + 6) % 7;
        setDay(dayIndex);
    }, []);

    if (!lunch || day < 0 || day >= 5 ||  !lunch[day]) {
        return null;
    }

    const todayData = lunch[day];
    const firstHalf = todayData?.tuotteet.slice(0, 5);
    const secondHalf = todayData?.tuotteet.slice(5);

    return (
        <div data-aos="fade-up" className="app__specialMenu flex__center section__padding" id="menu">
            <div data-aos="fade-left" className="app__specialMenu-title">
                <h1 className="headtext__cormorant">{inEnglish ? "Today Lunch - 12,70€" : "Päivän Lounas - 12,70€"}</h1>
                <h1 className="headtext__cormorant">{inEnglish ? "Starting Time" : "ARKISIN KLO"} 10.30 - 15:00</h1>
            </div>

            <div data-aos="fade-up" className="app__specialMenu-menu">
                <div className="app__specialMenu-menu_wine flex__center">
                    <div className="app__specialMenu_menu_items">
                        {firstHalf && firstHalf.map((item, index) => (
                            <LunchTodayItem key={item.id + index} title={item.nimi} price={item.tyyppi} tags={item.ainesosat} eng={item.eng_description} />
                        ))}
                    </div>
                </div>

                <div data-aos="fade-right" className="app__specialMenu-menu_img">
                    <img src={images.biryani} alt="menu__img" />
                </div>

                <div data-aos="fade-down" className="app__specialMenu-menu_cocktails flex__center">
                    <div className="app__specialMenu_menu_items">
                        {secondHalf && secondHalf.map((item, index) => (
                            <LunchTodayItem key={item.id + index} title={item.nimi} price={item.tyyppi} tags={item.ainesosat} eng={item.eng_description} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TodayLunch;
