import React from 'react'
import { MenuItem, Navbar } from '../components'
import CartInput from '../container/Cart/CartInput'
import { Footer } from '../container'

const CartOrderPAge = () => {
  return (
    <div>
      <Navbar />
      <CartInput />
      <MenuItem />
      <Footer />
    </div>
  )
}

export default CartOrderPAge
