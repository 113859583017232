import React from 'react'
import { Navbar } from '../components'
import OpeningTime from '../components/Weekupdate/OpeningTime'
import Gallery from '../components/Gallery/Gallery'
import { Footer } from '../container'

const GalleryPage = () => {
  return (
    <div>
      <Navbar/>
      <Gallery />
      <OpeningTime />
      <Footer />
    </div>
  )
}

export default GalleryPage
