import React, { useState, useEffect } from "react";
import "./CartIcon.css";
import { useDispatch, useSelector } from "react-redux";
import { setCartitemsNumber } from "../../State/State";
const CartIcon = () => {
  const [cartItems, setCartItems] = useState([]);
  const cartItemsNumber = useSelector(state => state.cartItemsNumber)
 

  const updateCart = () => {
    // Retrieve cart data from localStorage
    const cartData = localStorage.getItem("cart");
    if (cartData) {
      
      setCartItems(JSON.parse(cartData));
      
    }
  };

  useEffect(() => {
    updateCart();

    // Listen for custom event
    window.addEventListener("cartUpdated", updateCart);

    // Cleanup
    return () => {
      window.removeEventListener("cartUpdated", updateCart);
    };
  }, []);

  return (
    <div className="cart-icon">
      <span className="cart-count">{cartItemsNumber.length}</span>
      <i className="fa fa-shopping-cart fa-xl"></i>
    </div>
  );
};

export default CartIcon;
