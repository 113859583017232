import React, { useEffect } from 'react';

import './Header.css';
import { SubHeading } from '../../components';
import LeftHeader from './LeftHeader';
import { images } from '../../constants';

const Header = () => {
  useEffect(() => {
    const track = document.getElementById("image-track");
    
    if (track) {
      const handleOnDown = e => track.dataset.mouseDownAt = e.clientX;

      const handleOnUp = () => {
        track.dataset.mouseDownAt = "0";  
        track.dataset.prevPercentage = track.dataset.percentage;
      }

      const handleOnMove = e => {
        if(track.dataset.mouseDownAt === "0") return;
        
        const mouseDelta = parseFloat(track.dataset.mouseDownAt) - e.clientX,
              maxDelta = window.innerWidth / 2;
        
        const percentage = (mouseDelta / maxDelta) * -100,
              nextPercentageUnconstrained = parseFloat(track.dataset.prevPercentage) + percentage,
              nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100);
        
        track.dataset.percentage = nextPercentage;
        
        track.animate({
          transform: `translate(${nextPercentage}%, -50%)`
        }, { duration: 1200, fill: "forwards" });
        
        for(const image of track.getElementsByClassName("image")) {
          image.animate({
            objectPosition: `${100 + nextPercentage}% center`
          }, { duration: 1200, fill: "forwards" });
        }
      }

      window.onmousedown = e => handleOnDown(e);
      window.ontouchstart = e => handleOnDown(e.touches[0]);
      window.onmouseup = e => handleOnUp(e);
      window.ontouchend = e => handleOnUp(e.touches[0]);
      window.onmousemove = e => handleOnMove(e);
      window.ontouchmove = e => handleOnMove(e.touches[0]);

      // Clean up event listeners on component unmount
      return () => {
        window.onmousedown = null;
        window.ontouchstart = null;
        window.onmouseup = null;
        window.ontouchend = null;
        window.onmousemove = null;
        window.ontouchmove = null;
      };
    }
  }, []); // Empty dependency array means this effect runs once when the component mounts

  return (
    <div className='app_header'>
      <LeftHeader />
      <div id="image-track" data-mouse-down-at="0" data-prev-percentage="0">
        <img className='image' draggable="false" src={images.gallery1} alt="Gallery 1" />
        <img className='image' draggable="false" src={images.gallery2} alt="Gallery 2" />
        <img className='image' draggable="false" src={images.gallery3} alt="Gallery 3" />
        <img className='image' draggable="false" src={images.gallery4} alt="Gallery 4" />
        <img className='image' draggable="false" src={images.gallery5} alt="Gallery 5" />
        <img className='image' draggable="false" src={images.gallery6} alt="Gallery 6" />
        <img className='image' draggable="false" src={images.gallery7} alt="Gallery 7" />
        <img className='image' draggable="false" src={images.gallery8} alt="Gallery 8" />
      </div>
    </div>
  );
};

export default Header;
