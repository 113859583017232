import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./CartInput.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setBooking, setCartItems } from "../../State/State";
import CryptoJS from 'crypto-js';
import axios from 'axios';


const SECRET_KEY = "Padama_akhanda"

const CartInput = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cartItems = useSelector((state) => state.cartItemsOrder);
  const SERVICE_ID = "service_7z3s43e";
  const USER_ID = "RkwEP1HJp62wHwjPE";
  const TEMPLATE_ID = "template_wmiy0gt";

  const serverOrders = async (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        setEmailError("Please enter a valid email.");
        return;
    }
    if (!name.trim()) {
        setNameError("Please enter a valid name.");
        return;
    }
    if (!phone.trim()) {
        setPhoneError("Please enter a valid phone.");
        return;
    }

    setEmailError("");
    setNameError("");
    setPhoneError("");

    const now = new Date(Date.now());
    const orderTime = now.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    const currentDate = now.toLocaleDateString("en-US");

    const totalAmount = cartItems.reduce(
        (total, item) =>
            total +
            parseFloat(item.price.toString().replace(",", ".")) * item.quantity,
        0
    );

    const orderDetails = {
        name,
        email,
        phone,
        cartItems,
        date: currentDate,
        time: orderTime,
        OrderDone:false,
        totalamout: totalAmount.toFixed(2)
    };

    // Encrypt the order details
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(orderDetails), SECRET_KEY).toString();

    try {
      const response = await axios.post("https://akhanda-backend.onrender.com"+'/api/orders', { data: ciphertext }, {
          headers: {
              'Content-Type': 'application/json'
          }
      });

      console.log(response.data);
  } catch (error) {
      console.error("There was an error creating the order!", error);
  }
};
  
  const placeOrder = (e) => {
    e.preventDefault(); // Prevent form from reloading the page

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email.");
      return;
    }
    if (!name.trim()) {
      setNameError("Please enter a valid name.");
      return;
    }
    if (!phone.trim()) {
      setPhoneError("Please enter a valid phone.");
      return;
    }

    setEmailError("");
    const now = new Date(Date.now());
    const orderTime = now.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const currentDate = now.toLocaleDateString("en-US");

    const totalAmount = cartItems.reduce(
      (total, item) =>
        total +
        parseFloat(item.price.toString().replace(",", ".")) * item.quantity,
      0
    );

    const orderDetails = {
      name,
      email,
      cartItems,
      b: false,
      date: currentDate,
      orderTime,
      OrderDone:false,
      totalAmount: totalAmount.toFixed(2),
    };

    const adminOrderDetails = {
      email: "akhandanepalilainenravintola@gmail.com",
      customerEmail: email,
      name,
      b: false,
      cartItems,
      message,
      phone,
      date: currentDate,
      orderTime,
      totalAmount: totalAmount.toFixed(2),
    };

    emailjs.send(SERVICE_ID, TEMPLATE_ID, orderDetails, USER_ID).then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        alert("Order Placed Successfully!");
      },
      (err) => {
        console.log("FAILED...", err);
        alert("FAILED");
        alert(err);
      }
    );

    emailjs.send(SERVICE_ID, "template_f6crjhu", adminOrderDetails, USER_ID).then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
       
        localStorage.setItem("cart", JSON.stringify([]));
        dispatch(setCartItems({ cart: [] }));
        dispatch(setBooking({booking:false}))
        navigate("/success");
      },
      (err) => {
        console.log("FAILED...", err);
      }
    );
  };

  return (
    <div className="App_CartInput">
      <div data-aos="fade-right" className="table-booking">
        <h2>sinun tiedot</h2>
        <form onSubmit={(e) => {serverOrders(e); placeOrder(e)}}>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="name">Nimi</label>
              <input
                type="text"
                id="name"
                placeholder="Kirjoita nimesi"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
              {nameError && <p className="error">{nameError}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="email">Sähköposti</label>
              <input
                type="email"
                id="email"
                placeholder="Syötä sähköpostiosoitteesi"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && <p className="error">{emailError}</p>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="phone">Yhteysnumero</label>
              <input
                type="tel"
                id="phone"
                placeholder="Syötä yhteystietosi"
                value={phone}
                required
                onChange={(e) => setPhone(e.target.value)}
              />
              {phoneError && <p className="error">{phoneError}</p>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="date">Date</label>
              <input
                type="date"
                id="date"
                value={date}
                required
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="time">Aika</label>
              <input
                type="time"
                id="time"
                value={time}
                required
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="message">Viesti </label>
            <textarea
              rows={6}
              id="message"
              placeholder="Kirjoita viestisi tähän"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <button type="submit">tilaa tavaroita</button>
        </form>
      </div>
    </div>
  );
};

export default CartInput;
