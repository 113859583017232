import React, { useRef, useState } from 'react'
import "./FindUs.css"
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import emailjs from "@emailjs/browser";

const FindUs = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_n8y4nub",
        "template_c4t4zlj",
        form.current, {
        publicKey: 'Kfes_MfBGnZDa1l1V',
      }
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          setEmail("")
          setMessage("")
          setName("")
          setPhone("")
          setSubject("")
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div  className='container-find'>
      <h1 data-aos="fade-right">Connect With Us</h1>
      <p data-aos="fade-right">We Would love to respond to your queries and help you succeed. Feel free to get in touch with us.</p>
      <div data-aos="fade-down" className='contact-box'>
        <div className='contact-left'>
          <h3>Sent your request</h3>
          <form className='' ref={form} onSubmit={sendEmail}>
            <div className='input-row'>
              <div className='input-group'>
                <label>Name</label>
                <input type='text' placeholder='Name' name="user_name" value={name} onChange={(e) => setName(e.target.value)} />
              </div>

              <div className='input-group'>
                <label>Email Address</label>
                <input type='text' placeholder='Email Address' name='user_email' value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className='input-group'>
                <label>Phone</label>
                <input type='text' placeholder='Phone' name='user_phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
              </div>
              <div className='input-group'>
                <label>Subject</label>
                <input type='Subject' placeholder='Subject' name='user_subjects' value={subject} onChange={(e) => setSubject(e.target.value)} />
              </div>

            </div>
            <label>Message</label>
            <textarea rows="8" placeholder='Your Message' name='user_message' value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
            <button className='custom__button' type='submit'>Send</button>
          </form>
        </div>
        <div className='contact-right'>
          <h3>Reach Us </h3>
          <div class="col">
            <div className='app__footer-links section__padding' >
              <div className='app__footer-information aboutus'>
                <IoLocationSharp size={40} color='white' />
                <div className='app__contact-information'>
                  <p style={{ color: 'white' }}>Löydä Meidät</p>
                  <p style={{ color: 'black' }}> Valimotie 2, 00380 HELSINKI</p>
                </div>
              </div>

              <div className='app__footer-information aboutus'>
                <FaPhoneAlt size={40} color='white' />
                <div className='app__contact-information'>
                  <p style={{ color: 'white' }}>Soita Meille</p>
                  <p style={{ color: 'black' }}>0400225222</p>
                </div>
              </div>
              <div className='app__footer-information aboutus'>
                <MdEmail size={40} color='white' />
                <div className='app__contact-information'>
                  <p  >Lähetä Sähköpostia</p>
                  <p style={{ color: 'black' }}>akhandaoy@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FindUs
