import React from "react";
import { MenuItem, Navbar } from "../components";
import { Footer } from "../container";
import LunchMenu from "../container/ThePortions/LunchMenu";
import OpeningTime from "../components/Weekupdate/OpeningTime";
import EventNotice from "../container/ThePortions/EventNotice";

const DaysMenu = () => {
  return (
    <div>
      <Navbar />
      <EventNotice />
      <LunchMenu />
      <MenuItem />
      <OpeningTime />
      <Footer />
    </div>
  );
};

export default DaysMenu;
