import React, { useEffect, useState } from "react";
import "./LunchMenu.css";
import { images } from "../../constants";
import LunchItems from "../../constants/data";
import CardItem from "./CardItem";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const LunchMenu = () => {
  
  const [fixed, setFixed] = useState(false);
  const lunch = useSelector(state => state.lunch)
  const [dayWeek, setDayWeek] = useState(lunch?lunch[0]:null);
  const navigate = useNavigate(null);
  console.log(dayWeek);
  const clickDay = (dayfound) => {
    setDayWeek(dayfound);
  };
  useEffect(() => {
    if(lunch){
      setDayWeek(lunch[0])
      
      
    }
  },[])
 
  const selectFixed = () => {
    if (window.scrollY >= 400) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };
  window.addEventListener("scroll", selectFixed);
  if (!lunch){
    return (
      <div></div>
    )
  }

  return (
    <div className="app__menu">
      <div className="app__lunchmenu-frontphoto">
        <img src={images.ravintola} />
        <div className="app_lunchmenu_buffet_front" data-aos="fade-right">
          <div className="APP_LUNCHMENU-H1">
            LOUNASBUFFET - Є12,70
          </div>
          {/*<div className='lunch-time-buffet-available'>Arkisin KLO 10:30 - 15:00</div> */}
        </div>
        <div className="app__lunchmenu-front-buttons">
          <a onClick={() => navigate("/alacarte")} className="custom__button">
            A-La-Carte
          </a>
          <a onClick={() => navigate("/lounas")} className="custom__button">
            Lounas
          </a>
        </div>
      </div>
      <div
        className={
          fixed
            ? "app__lunchmenu-left-smallscreen fixed-class"
            : "app__lunchmenu-left-smallscreen"
        }
      >
        {lunch &&
          lunch.map((data, index) => {
            return (
              <div
                className={`app__lunchmenu-button ${data.päivä == dayWeek.päivä && "clicked-button-smallscreen"
                  }`}
                key={index}
                onClick={(e) => clickDay(data)}
              >
                {data.päivä}
              </div>
            );
          })}
      </div>

      <div className="app__lunchmenu">
        <div
          className="app__lunchmenu-left"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <h1>Lounas</h1>
          <div className="app__lunchmenu-buttons">
            <div className="app__lunch-image1">
              <img src={images.tomato} />
            </div>
            <div className="app__lunch-image2">
              <img src={images.onions} />
            </div>
            {lunch &&
              lunch.map((data, index) => {
                return (
                  <div
                    className={`app__lunchmenu-button ${data.päivä == dayWeek.päivä && "clicked-button"
                      }`}
                    key={index}
                    onClick={(e) => clickDay(data)}
                  >
                    {data.päivä}
                  </div>
                );
              })}
          </div>
        </div>
        <div data-aos="fade-left" className="app__lunchmenu-right">
          <h1>{dayWeek.päivä}</h1>
          <div className="app__lunchMenu-items">
            {dayWeek.tuotteet &&
              dayWeek.tuotteet.map((data, index) => {
                return (
                  <CardItem
                    type={data.tyyppi}
                    ingredients={data.ainesosat}
                    id={index+1}
                    itemName={data.nimi}
                    data={data}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LunchMenu;
