import React from 'react'
import { MenuItem, Navbar } from '../components'
import { FindUs, Footer } from '../container'
import OpeningTime from '../components/Weekupdate/OpeningTime'


const Contact = () => {
  return (
    <div>
    <Navbar/>
      <FindUs />
      <MenuItem />
      <OpeningTime />
      <Footer/>
    </div>
  )
}

export default Contact
