import React, { useEffect, useState } from 'react'
import "./OpeningTime.css"
import { useDispatch, useSelector } from 'react-redux'
import { setStartingTime } from '../../State/State'





const OpeningTime = () => {
  const [date, setDate] = useState(null)
  const startingDate = useSelector(state => state.startingDate)
  const dispatch= useDispatch()
  const inEnglish = useSelector(state => state.inEnglish)
  useEffect(() => {

    var date = new Date();


    // Define opening hours for each day
    const options = { timeZone: 'Europe/Helsinki' };

    // Format the date in the Finnish timezone
    const formattedDate = new Date(date.toLocaleString('en-US', options));
    

    // Define opening hours for each day
    const openingHours = [
      { day: 'SU', hours: '12.00 - 21.00' },
      { day: 'MA', hours: '10.30 - 22.00' },
      { day: 'TI', hours: '10.30 - 22.00' },
      { day: 'KE', hours: '10.30 - 22.00' },
      { day: 'TO', hours: '10.30 - 22.00' },
      { day: 'PE', hours: '10.30 - 22.00' },
      { day: 'LA', hours: '12.00 - 22.00' }
    ];


    // Get the day of the week (0-6) in Finland timezone
    const dayIndex = formattedDate.getDay();

    // Get the opening hours for the corresponding day
    const openingHoursForDay = openingHours[dayIndex];
    // Print the day and opening hours
    setDate(`${openingHoursForDay.hours}`);
    dispatch(setStartingTime({startingTime: openingHoursForDay.hours}))
  }, [])

  return (
    <>
      <div data-aos="fade-up" className='app__lunchtime'>
        <h2>{inEnglish?"Opening Hours":"AUKI TÄNÄÄN"}</h2>
        <h2>{date}</h2>
      </div>
      <div data-aos="fade-up" className='app__specialtime'>
        <h2>{inEnglish?"Lunch Time":"LOUNASBUFFET"}</h2>
        <h2>10:30-15:00</h2>
      </div> 
    </>

  )
}

export default OpeningTime
