import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { setCartItems, setCartitemsNumber } from "../../State/State"; // Adjust the path as necessary
import "./Cart.css";
import styled from "styled-components"

const todayDate = new Date(Date.now());
const isMayDay = todayDate.getMonth() === 4 && todayDate.getDate() === 1;
const CartHeading = styled.h1`
font-family: Verdana, Geneva, Tahoma, sans-serif;
font-size: 70px ;
color: transparent;
  -webkit-text-stroke: 2px rgb(190, 115, 16);
  position: relative;
  &::before {
    content: "Cart" !important;
  position: absolute;
  width: 0%;
  height: 100% !important;
  overflow: hidden;
  color: rgb(190, 115, 16);
  transition: 1s ease-in-out;
  }
  &:hover::before{
    width: 100%;
  filter: drop-shadow(0 0 25px rgb(190, 115, 16));
}
`
const KorttiHeading = styled.h1`
font-family: Verdana, Geneva, Tahoma, sans-serif;
font-size: 70px ;
color: transparent;
  -webkit-text-stroke: 2px rgb(190, 115, 16);
  position: relative;
  &::before {
    content: "Kortti" !important;
  position: absolute;
  width: 0%;
  height: 100% !important;
  overflow: hidden;
  color: rgb(190, 115, 16);
  transition: 1s ease-in-out;
  }
  &:hover::before{
    width: 100%;
  filter: drop-shadow(0 0 25px rgb(190, 115, 16));
}
`



const OrderSummary = ({ items }) => {
  const inEnglish = useSelector(state => state.inEnglish)
  const dispatch = useDispatch()
  const total = items.reduce(
    (acc, item) =>
      acc + parseFloat(item.price.toString().replace(",", ".")) * item.quantity,
    0
  );

  const alv = 0.14; // Assuming ALV (VAT) is 14%
  const payableAmount = total

  return (
    <div className="order-summary">
      <h2>{inEnglish ? "Order Summary" : "Noudan Itse"}</h2>
      <div className="order-items">
        {items.map((item, index) => (
          <div key={index} className="order-item">
            <div className="product">{`${item.quantity} x ${item.item}`}</div>
            <div className="price">
              <span>
                {(
                  parseFloat(item.price.toString().replace(",", ".")) *
                  item.quantity
                ).toFixed(2)}{" "}
                €
              </span>
            </div>
          </div>
        ))}

        <div className="order-totals">
          <div className="summary_total">
            <div>{inEnglish ? "Total" : "YHTEENSÄ"}</div>
            <div>{total.toFixed(2)} €</div>
          </div>
          <div className="payable-amount">
            <div>{inEnglish?"Payable Amount":"MAKSETTAVA"}</div>
            <div>{payableAmount.toFixed(2)} €</div>
          </div>
          <div className="alv">
            {inEnglish?"ALV (14% included)":"Sis. alv(14%) "}: {(total * alv).toFixed(2)}{" "}
            €
          </div>
        </div>
      </div>
    </div>
  );
};

const Cart = () => {
  const dispatch = useDispatch();
  const inEnglish = useSelector(state => state.inEnglish)
  const [cartItems, setCartItemsState] = useState([]);
  const navigate = useNavigate();
  const cartItemsNUmber = useSelector(state => state.cartItemsNumber)


  const complementaryItem = {
    id: "33",
    item: "LASSI - (Banana tai Mangotai Strawberry)",
    price: "0,0",
    quantity: 1,
  };

  if (
    isMayDay &&
    cartItems.length > 0 &&
    cartItems.findIndex((el) => el?.id === "33") === -1
  ) {
    cartItems.push(complementaryItem);
  }

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("cart"));
    if (items) {
      setCartItemsState(items.map((item) => ({ ...item, quantity: 1 })));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);

  const increaseQuantity = (index) => {
    if (isMayDay && cartItems[index]?.id === "33") {
      return;
    }

    const newCartItems = [...cartItems];
    newCartItems[index].quantity += 1;
    setCartItemsState(newCartItems);
    dispatch(setCartitemsNumber({ cartItemsNumber: newCartItems }))
  };

  const decreaseQuantity = (index) => {
    const newCartItems = [...cartItems];
    if (newCartItems[index].quantity > 1) {
      newCartItems[index].quantity -= 1;
    }
    setCartItemsState(newCartItems);
    dispatch(setCartitemsNumber({ cartItemsNumber: newCartItems }))
  };

  const deleteItem = (index) => {
    if (
      isMayDay &&
      cartItems.length === 2 &&
      cartItems.findIndex((el) => el?.id === "33") !== -1 &&
      cartItems[index]?.id !== "33"
    ) {
      setCartItemsState([]);
      return;
    }
    const newCartItems = [...cartItems];
    newCartItems.splice(index, 1);
    setCartItemsState(newCartItems);
    dispatch(setCartitemsNumber({ cartItemsNumber: newCartItems }))
  };

  const proceedOrder = () => {
    navigate("/orderCart");
    dispatch(setCartItems({ cart: cartItems }));
  };

  return cartItems.length === 0 ? (
    <div className="cart__empty-container">
      <img
        className="cart__empty-icon"
        width={136}
        height={136}
        src="https://cdn3.iconfinder.com/data/icons/shopping-and-ecommerce-28/90/empty_cart-512.png"
        alt="empty cart"
      />
      <h2 style={{ color: "white" }}>OSTOSKORISI ON TYHJÄ..!</h2>

      <button className="cart__empty-back">
        <a href="/alacarte">Back to shopping</a>
      </button>
    </div>
  ) : (
    <div>
      <div className="app__cart_div_1">{inEnglish?<CartHeading className="app__meals-h1">Cart</CartHeading>:<KorttiHeading className="app__meals-h1">Kortti</KorttiHeading>}</div>
      <div className="cart__container">
        <div className="cart">
          <div className="cart-header">
            <div className="product1">{inEnglish ? "Product" : "Tuote"}</div>
            <div className="price">{inEnglish ? "Price" : "Hinta"}</div>
            <div className="quantity">{inEnglish ? "Amount" : "Määrä"}</div>
            <div className="total">{inEnglish ? "Total" : "Yhteensä"}</div>
            <div className="delete">{inEnglish ? "Remove" : "Poistaa"}</div>
          </div>
          <div className="cart-items">
            {cartItems.map((item, index) => (
              <div className="cart-item" key={index}>
                <div className="product">{item.item}</div>
                <div className="price">{item.price} €</div>
                <div className="quantity">
                  <button onClick={() => decreaseQuantity(index)}>-</button>
                  <span>{item.quantity}</span>
                  <button onClick={() => increaseQuantity(index)}>+</button>
                </div>
                <div className="total">
                  {(
                    parseFloat(item.price.replace(",", ".")) * item.quantity
                  ).toFixed(2)}{" "}
                  €
                </div>
                <div className="delete">
                  <button onClick={() => deleteItem(index)}>
                    <MdDelete color="red" size={22} />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <button className="App_cartInput_Button" onClick={proceedOrder}>
            {inEnglish ? "Proceed to Checkout" : "Kirjaudu jatkaaksesi"}
          </button>
        </div>

        <div className="summary__container">
          <OrderSummary items={cartItems} />
        </div>
      </div>
    </div>
  );
};

export default Cart;
