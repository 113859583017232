

import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import foodora from "../assets/foodora.png"
import wolt from "../assets/wolt.png"
import foodora_and_wolt from "../assets/foodora_and_wolt.png"
import chef_cooking from "../assets/chef_cooking.png"
import logo_footer from "../assets/logo_footer.png"
import vector from "../assets/vector3.png"
import biryani from "../assets/biryani.png"
import chicken from "../assets/chicken.png"
import tomato from "../assets/tomato.png"
import onions from "../assets/onions.png"
import ravintola from "../assets/ravintola.jpg"
import bg from "../assets/bg.png"
import gallery1 from "../assets/gallery1.jpg"
import gallery2 from "../assets/gallery2.jpg"
import gallery3 from "../assets/gallery3.jpg"
import gallery4 from "../assets/gallery4.jpg"
import gallery5 from "../assets/gallery5.jpg"
import gallery6 from "../assets/gallery6.jpg"
import gallery7 from "../assets/gallery7.jpg"
import gallery8 from "../assets/gallery8.jpg"
import fi from "../assets/fi.png"
import en from "../assets/uk.png"



export default {
  
 foodora,
 wolt,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  foodora_and_wolt,
  chef_cooking,
 vector,
  biryani,
  chicken,
  logo_footer,tomato,onions,ravintola,
  bg,
  gallery1,gallery2,gallery3,gallery4,gallery5,gallery6,gallery7,gallery8,en,fi
};
