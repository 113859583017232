import React from 'react'
import "./Intro.css"
import { images } from '../../constants'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
const OrderHeading = styled.h1`
 font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 70px !important;
    color: transparent;
    -webkit-text-stroke: 2px rgb(190, 115, 16) ;
    position: relative;
    &::before{
      content: "Order" !important;   
    position: absolute;
    width: 0%;
    height: 100%!important;
    overflow: hidden;
    color: rgb(190, 115, 16);
    transition: 1s ease-in-out;
    }
    &:hover::before{
      width: 100%;
    filter: drop-shadow(0 0 25px rgb(190, 115, 16) );
    }
`

const TillaHeading = styled.h1`
 font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 70px !important;
    color: transparent;
    -webkit-text-stroke: 2px rgb(190, 115, 16) ;
    position: relative;
    &::before{
      content: "Tilaa" !important;   
    position: absolute;
    width: 0%;
    height: 100%!important;
    overflow: hidden;
    color: rgb(190, 115, 16);
    transition: 1s ease-in-out;
    }
    &:hover::before{
      width: 100%;
    filter: drop-shadow(0 0 25px rgb(190, 115, 16) );
    }
`

const Intro = () => {
  const inEnglish = useSelector(state => state.inEnglish)
  return (

    <div data-aos="fade-up" style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center",flexDirection:"column" }}>
      {inEnglish?<OrderHeading className=' app__meals-h1'>Order Now</OrderHeading>:<TillaHeading>Tilaa Nyt</TillaHeading>}
      <div className='app__intro'>

        <div className='app__logos'>
          <a className='image1' target='_blank' href='https://www.foodora.fi/restaurant/hos3/akhanda-nepalilainen-ravintola' ><img src={images.foodora} className='app___logos-foodora' /></a>
          <a className='image2' target='_blank' href='https://wolt.com/en/fin/helsinki/restaurant/akhanda-nepalilainen-ravintola'><img src={images.wolt} className='app___logos-foodora' /></a>
        </div>
      </div>
    </div>

  )
}

export default Intro
