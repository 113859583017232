import React, { useEffect } from "react";

import "./App.css";

import { Route, Routes } from "react-router";
import Home from "./Page/Home";
import Contact from "./Page/Contact";
import DaysMenu from "./Page/DaysMenu";
import MainMenu from "./Page/MainMenu";
import Gallery from "./components/Gallery/Gallery";
import GalleryPage from "./Page/GalleryPage";
import CartPage from "./Page/CartPage";
import CartOrderPAge from "./Page/CartOrderPAge";
import SucessfullyOrderPage from "./Page/SucessfullyOrderPage";
import { useSelector } from "react-redux";
import { setLunch } from "./State/State";

const App = () => {
  
  return (
    <>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/alacarte" element={<MainMenu />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/lounas" element={<DaysMenu />} />
      <Route path="/gallery" element={<GalleryPage />} />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/orderCart" element={<CartOrderPAge />} />
      <Route path="/success" element={<SucessfullyOrderPage /> } />
    </Routes>
  </>
  )
}
  


export default App;
