import React from "react";
import Cart from "../container/Cart/Cart";
import Footer from "../container/Footer/Footer";
import { MenuItem, Navbar } from '../components'

const CartPage = () => {
  return (
    <>
      <Navbar />
      <Cart />
      <MenuItem />
      <Footer />
    </>
  );
};

export default CartPage;
