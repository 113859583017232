import React from "react";
import "./MenuItem.css";
import { IoHomeOutline } from "react-icons/io5";
import { MdOutlineFoodBank } from "react-icons/md";
import { PiBowlFood } from "react-icons/pi";
import { FaHome } from "react-icons/fa";
import { FaBowlFood } from "react-icons/fa6";
import { MdFoodBank } from "react-icons/md";
import { useNavigate } from "react-router";
import { RiGalleryFill } from "react-icons/ri";
import { LuShoppingCart } from "react-icons/lu";
import CartIcon from "../../container/ThePortions/CartIcon";
import { GoHome } from "react-icons/go";

const MenuItem = () => {
  const navigate = useNavigate();
  return (
    <div className="app__navbar_smallscreen_lower_options">
    <div className="app_navbar_smallscreen-lower-icons">
        <GoHome size={20} />
        <a onClick={() => navigate("/")}>Home</a>
      </div>
      <div className="vl" style={{height:"43px"}}></div>
      <div className="app_navbar_smallscreen-lower-icons">
        <FaBowlFood size={20} />
        <a onClick={() => navigate("/lounas")}>Lounas</a>
      </div>
      <div className="vl" style={{height:"43px"}}></div>
      <div className="app_navbar_smallscreen-lower-icons">
        <MdFoodBank size={20} />
        <a onClick={() => navigate("/alacarte")}>A-La-Carte</a>
      </div>
      <div className="vl" style={{height:"43px"}}></div>
      <div className="app_navbar_smallscreen-lower-icons">

        <a onClick={() => navigate("/cart")} className="app__cart-menuItem">
          <span>
            <CartIcon size={20} />
          </span>
          <span
            style={{
              fontFamily: "Poppins",
              fontWeight: "300",

            }}
          >
            KORRIN
          </span></a>
      </div>
    </div>
  );
};

export default MenuItem;
